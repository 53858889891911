.markdownStyle {
  max-width: 960px;
  height: -webkit-fill-available;
  overflow: auto;
  margin: auto;
  padding: 0 16px;
}
.markdownStyle h1, .markdownStyle h2, .markdownStyle h3, .markdownStyle h4, .markdownStyle h5, .markdownStyle h6 {
  width: fit-content;
  box-sizing: border-box;
  text-align: center;
  margin: 16px 0 -12px 0;
}