.leaflet-container {
  z-index: 0;
}

.leaflet-control-attribution a,
.leaflet-control-attribution a:visited {
  color: #007cc0;
  text-decoration: underline;
  cursor: pointer;
}

.leaflet-control-attribution a:hover,
.leaflet-control-attribution a:focus {
  color: #005686;
}

.leaflet-popup-content {
  margin: 8px;
}

.leaflet-popup-content p {
  margin: 0;
}

.leaflet-container a.leaflet-popup-close-button {
  font-size: 20px;
}

.marker svg,
.inspectie-marker svg {
  pointer-events: none;
}

.leaflet-control-layers {
  display: none;
}
.leaflet-bottom.leaflet-left {
  margin-left: 0;
  transition: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
}
.leaflet-control-scale-line {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid lightgray;
}
.leaflet-touch .leaflet-control-attribution {
  border-radius: 4px;
  margin: 0 4px 4px 0;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border: 1px solid lightgray;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid lightgray;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-radius: 4px 4px 0 0;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-radius: 0 0 4px 4px;
}
.leaflet-top .leaflet-control {
  margin-top: 8px;
}
.leaflet-right .leaflet-control {
  margin-right: 8px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 8px;
}
.leaflet-left .leaflet-control {
  margin-left: 8px;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  box-shadow: 0 1px 6px rgb(0 0 0 / 30%);
  border-radius: 4px;
}

@media only screen and (max-width: 800px) {
  .leaflet-top .leaflet-control {
    margin-top: 4px;
  }
  .leaflet-right .leaflet-control {
    margin-right: 4px;
  }
  .leaflet-bottom .leaflet-control {
    margin-bottom: 4px;
  }
  .leaflet-left .leaflet-control {
    margin-left: 4px;
  }
}


@media only screen and (max-width: 800px) {
  .leaflet-control-container .timecontrol-dateslider {
    display: none;
  }
}
