/* Globale index css */
html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:visited {
  color: #007cc0;
  text-decoration: underline;
  cursor: pointer;
}

a:hover,
a:focus {
  color: #005686;
}

* {
  outline: none;
}

::-webkit-scrollbar {
  width: 6px;
  max-height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}