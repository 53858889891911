/* Globale app css */
#root, .App {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(243 245 249);
}

.loader-background {
  display: flex;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999999999;
}

.loader-default {
  background: white;
  margin: auto;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.transition {
  transition: 225ms linear 0ms !important;
}

.react-draggable-dragging {
  cursor: grabbing !important;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hkv-title {
  background: #007cc0;
  position: relative;
  width: fit-content;
  max-width: calc(100% - 34px);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1.75rem;
}

.hkv-title span {
  position: relative;
  z-index: 1;
}

.hkv-title::after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #007cc0;
  border-radius: 4px;
  transform-origin: bottom left;
  -ms-transform: skew(-30deg, 0deg);
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-15deg, 0deg);
}

* {
  outline: none;
}

@media only screen and (max-width: 600px) {
  .hkv-title {
    font-size: 1.45rem;
  }
}