div[role=presentation] .dialog-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 46px;
  margin-top: 16px;
}
div[role=presentation] .mobilestepper {
  background: #fafafa;
  width: 100%;
  padding: 0 4px;
}
div[role=presentation] .dialog-button {
  margin: 4px 0 4px 8px;
}
.react-swipeable-view-container {
  height: 100%;
}
.react-swipeable-view-container > div {
  height: 100%;
}