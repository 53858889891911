.App .drawerPaperBottomOpenLeft div:first-child {
  margin-left: calc(360px + 16px);
}

.App .drawerPaperBottomOpenRight div:first-child {
  margin-right: calc(360px + 16px);
}

.App .drawerButtonOpenLeft {
  left: calc(360px + 16px + 8px);
}

.leaflet-left, .leaflet-right {
  transition: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
}

.offset {
  margin-left: 60px !important;
}

/* kleiner dan 1500px */
@media only screen and (max-width: 1500px) {
  .App .drawerPaperBottomOpenLeft div:first-child {
    margin-left: calc(280px + 16px);
  }

  .App .drawerPaperBottomOpenRight div:first-child {
    margin-right: calc(280px + 16px);
  }

  .App .drawerButtonOpenLeft {
    left: calc(280px + 16px + 8px);
  }
}

/* kleiner dan 600px */
@media only screen and (max-width: 600px) {
  .App .drawerPaperBottomOpenLeft div:first-child {
    margin-left: 16px;
  }

  .App .drawerPaperBottomOpenRight div:first-child {
    margin-right: 16px;
  }

  .App .drawerButtonOpenLeft {
    left: 8px;
  }
}

@media only screen and (max-width: 600px) {
  /* wanneer kleiner dan 600px */
  .moveLAbsolute {
    left: 0 !important;
  }
  .moveL {
    margin-left: 0 !important;
  }
  .moveLOffset {
    margin-left: 60px !important;
  }
  .moveR {
    margin-right: 0;
  }
  .moveRAbsolute {
    right: 0 !important;
  }
}

/* wanneer groter dan 600px */
@media only screen and (min-width: 600px) {
  .moveLAbsolute {
    left: 280px !important;
  }
  .moveL {
    margin-left: 280px !important;
  }
  .moveLOffset {
    margin-left: 340px !important;
  }
  .moveR {
    margin-right: 280px;
  }
  .moveRAbsolute {
    right: 280px !important;
  }
}
@media only screen and (min-width: 1500px) {
  /* wanneer groter dan 1500px */
  .moveLAbsolute {
    left: 360px !important;
  }
  .moveL {
    margin-left: 360px !important;
  }
  .moveLOffset {
    margin-left: 420px !important;
  }
  .moveR {
    margin-right: 360px;
  }
  .moveRAbsolute {
    right: 360px !important;
  }
}